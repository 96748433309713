import "./Editor.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { decode, encode } from "utils/compress";
import { rgbArr2str, rgbSubArr2str } from "utils/color";

import { Icon } from "./Icon";
import { SketchPicker } from "react-color";
import { readImageFile } from "utils/readFile";
import { useAdmin } from "hooks/useAdmin";

const WHITE = (255 << 16) + (255 << 8) + 255;
const CANVAS_SIZE = 512;

export default function Editor({
  value,
  onChange,
  onClose,
  size,
  setSize,
  x,
  y,
}: {
  value: string;
  onChange: Function;
  onClose: Function;
  size: number;
  setSize: Function;
  x: number;
  y: number;
}) {
  const dataRef = useRef(Buffer.alloc(size * size * 3, WHITE));
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [color, setColor] = useState<[number, number, number]>([255, 255, 255]);
  const [isPressing, setIsPressing] = useState(false);
  const [internalSize, setInternalSize] = useState(`${size}`);

  const { isAdmin } = useAdmin();

  const SAVE_KEY = `image`;
  // const SAVE_KEY = `image-${x}/${y}`;

  const refresh = useCallback(() => {
    if (canvasRef.current === null) return;
    const ctx = canvasRef.current.getContext("2d");
    if (ctx === null) return;

    const data = dataRef.current;
    const unit = CANVAS_SIZE / size;
    ctx.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
    for (let y = 0; y < size; y++) {
      for (let x = 0; x < size; x++) {
        const index = (y * size + x) * 3;
        const colorStr = rgbSubArr2str(data, index);
        ctx.fillStyle = colorStr;
        ctx.fillRect(x * unit, y * unit, unit, unit);
      }
    }
  }, [size]);

  useEffect(() => {
    // Apply change from outside
    const outputLength = size * size * 3;
    const output = Buffer.alloc(outputLength);
    const input = decode(value);
    const length = Math.min(input.length, outputLength);
    for (let i = 0; i < length; i++) output[i] = input[i];
    dataRef.current = output;
    refresh();
  }, [refresh, size, value]);

  function handleRelease() {
    const encoded = encode(dataRef.current);
    onChange(encoded);
    setIsPressing(false);
  }

  function onDraw(e: React.MouseEvent<HTMLElement>) {
    if (canvasRef.current === null) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const { clientX, clientY } = e;
    const x = Math.floor(((clientX - rect.left) * size) / CANVAS_SIZE);
    const y = Math.floor(((clientY - rect.top) * size) / CANVAS_SIZE);
    const index = (y * size + x) * 3;
    const data = dataRef.current;
    data[index + 0] = color[0];
    data[index + 1] = color[1];
    data[index + 2] = color[2];
    refresh();
  }

  function handleMouseDown(e: React.MouseEvent<HTMLElement>) {
    setIsPressing(true);
    onDraw(e);
  }

  function handleMouseMove(e: React.MouseEvent<HTMLElement>) {
    if (isPressing) onDraw(e);
  }

  function handleMouseRelease() {
    setIsPressing(false);
  }

  const getClear = (color: number) => () => {
    dataRef.current = Buffer.alloc(size * size * 3, color);
    refresh();
  };

  async function setFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (!files) return;
    if (files.length === 0) return;
    const file = files[0];
    const fileData = await readImageFile(file, size);
    dataRef.current = fileData;
    refresh();
  }

  function handleSizeUpdate(value: string) {
    let newSize = Math.floor(+value);
    let newValue = value;
    if (isNaN(newSize) || newSize < 1 || value === "") newSize = 1;
    if (newSize > 128) newValue = "128";
    if (value !== "" && newSize < 1) newValue = "1";
    setSize(newSize);
    setInternalSize(newValue);
  }

  function save() {
    if (window.confirm("Are you sure to overwrite to local storage? Existing saved image will be removed.") === true) {
      localStorage.setItem(SAVE_KEY, value);
      window.alert("Successfully saved to local storage of browser.");
    }
  }

  function load() {
    if (window.confirm("Are you sure to load from local storage? Your draft will be removed.") === true) {
      const savedValue = localStorage.getItem(SAVE_KEY);
      onChange(savedValue);
      window.alert("Successfully loaded from local storage of browser.");
    }
  }

  useEffect(refresh, [refresh, size]);

  return (
    <div className="editor" onMouseUp={handleRelease} onMouseLeave={handleRelease}>
      <div className="color-picker">
        <button className="box" onClick={() => onClose(true)}>
          <Icon>publish</Icon> PUBLISH
        </button>
        <div className="save-area">
          <button className="box" onClick={save}>
            SAVE TEMPORARILY
          </button>
        </div>
        <div className="load-area">
          <button className="box" onClick={load}>
            LOAD TEMPORARY DATA
          </button>
        </div>
        <div></div>
        <SketchPicker
          color={rgbArr2str(color)}
          onChange={({ rgb: { r, g, b } }) => setColor([r, g, b])}
          disableAlpha={true}
        ></SketchPicker>
      </div>
      <div className="pixel-area">
        <canvas
          width={CANVAS_SIZE}
          height={CANVAS_SIZE}
          ref={canvasRef}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseRelease}
        ></canvas>
      </div>
      <div className="toolbar">
        <button className="box" onClick={getClear(WHITE)}>
          RESET TO WHITE
        </button>
        <button className="box" onClick={getClear(0)}>
          RESET TO BLACK
        </button>
        <div className="box size">
          <div className="unit">SIZE</div>
          <input type="number" value={internalSize} onChange={(e) => handleSizeUpdate(e.target.value)}></input>
          <div className="unit">px</div>
        </div>
        <div className="box position">
          POSITION<div className="empty"></div>({x},{y})
        </div>
        <label htmlFor="imagefile-upload" className="box" hidden={!isAdmin}>
          FILE UPLOAD
        </label>
        <input type="file" onChange={setFile} hidden id="imagefile-upload"></input>
      </div>
      <button className="button-close" onClick={() => onClose(false)}>
        <strong>
          <Icon>close</Icon>
        </strong>
      </button>
    </div>
  );
}
