import { PAB_ABI, PAB_ADDRESS } from "../config";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import BROWSER from "./browser";
import Web3 from "web3";

const DEFAULT_NODE = "wss://mainnet.infura.io/ws/v3/71e36f51e691444da019f6a9f8960dd4";
const METAMASK_INSTALL_LINKS: { [key: string]: string } = {
  Chrome: "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
  Edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm",
  Firefox: "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
  iOS: "https://apps.apple.com/us/app/metamask/id1438144202",
  Android: "https://play.google.com/store/apps/details?id=io.metamask",
};

interface IWeb3Context {
  web3: Web3;
  contract: any;
  account: string | null;
  enabled: boolean;
  connect: Function;
}

declare global {
  interface Window {
    ethereum: any;
  }
}

const Web3Context = createContext<IWeb3Context>({
  account: "",
  connect: () => {},
  web3: new Web3(),
  contract: null,
  enabled: false,
});

export function useWeb3() {
  return useContext(Web3Context);
}

export const withAccount = (Component: Function) => (props: any) => {
  const [web3, setWeb3] = useState(new Web3(DEFAULT_NODE));
  const [account, setAccount] = useState<string | null>(null);
  const [contract, setContract] = useState<any>(null);
  const [enabled, setEnabled] = useState(false);

  const connect = useCallback(() => {
    (async () => {
      let account = null;
      if (window.ethereum) {
        const newWeb3 = new Web3(window.ethereum);
        setWeb3(newWeb3);

        try {
          const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
          account = accounts[0].toLowerCase();
          setAccount(account);
        } catch {}
      } else {
        const browser = BROWSER;
        if (browser && browser in METAMASK_INSTALL_LINKS) {
          const metamaskInstallLink = METAMASK_INSTALL_LINKS[browser];
          const tab = window.open(metamaskInstallLink, "_blank");
          if (tab) tab.focus();
        }
      }
      console.log("Your account :", account);
    })();
  }, []);

  useEffect(() => {
    const contract = new web3.eth.Contract(PAB_ABI, PAB_ADDRESS, { from: account || undefined });
    setContract(contract);
    setEnabled(true);
  }, [web3, account]);

  useEffect(connect, [connect]);

  if (!enabled)
    return (
      <div>
        <h1>Metamask Connection Required!</h1>
        <div>
          <button>Connect to Metamask</button>
        </div>
      </div>
    );

  return (
    <Web3Context.Provider value={{ web3, account, connect, contract, enabled }}>
      <Component {...props} />
    </Web3Context.Provider>
  );
};
