import "./App.scss";

import { IData, useToken } from "hooks/useToken";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWeb3, withAccount } from "utils/web3";

import Checkbox from "components/CheckBox";
import Editor from "components/Editor";
import { Icon } from "components/Icon";
import { Name } from "components/Name";
import { decode } from "utils/compress";
import { rgbSubArr2str } from "utils/color";
import { useAdmin } from "hooks/useAdmin";
import { useEvents } from "./hooks/useEvents";
import { useSearchParams } from "react-router-dom";

import ReactGA from "react-ga4";

// Interfaces

interface IBlock {
  x: number;
  y: number;
  data: string;
  size: number;
}

// Constants

const MIN_BLOCK_SIZE = 48;
const MAX_BLOCK_SIZE = 2048;
const ETH_TO_WEI = BigInt("1,000,000,000,000,000,000".replaceAll(",", ""));

// Utility functions

function ethToWei(eth: string | number | bigint) {
  const PRECISION = 10000000000;
  try {
    return BigInt(eth) * ETH_TO_WEI;
  } catch {
    return (BigInt(Math.floor(Number(eth) * PRECISION)) * ETH_TO_WEI) / BigInt(PRECISION);
  }
}

function weiToEth(wei: number | bigint | string) {
  const PRECISION = 10000000000;
  const t = (BigInt(wei) * BigInt(PRECISION)) / ETH_TO_WEI;
  const r = Number(t) / PRECISION;
  return r;
}

function getBlockHash(x: number, y: number) {
  return `${x},${y}`;
}

function dateToString(date: Date) {
  function suffix(i: number) {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const month = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
  return `${month[date.getMonth()]} ${suffix(date.getDate())} ${date.getFullYear()}`;
}

function Address({ children }: { children: string }) {
  // const { admin } = useAdmin();
  const headLength = children.startsWith("0x") ? 6 : 4; // 6: In case of Wallet Addr, 4: In case of Token ID
  const shorten = children.slice(0, headLength) + " ... " + children.slice(-4, children.length);
  return (
    <abbr about={children} title={children}>
      {shorten}
    </abbr>
  );
}

/**
 * HARDCORDED mint-check function.
 * Caution: This function must be updated whenever tile is minted.
 * @param x X position of tile
 * @param y Y position of tile
 * @returns whether tile is minted
 */
function isPositionMinted(x: number, y: number) {
  return -3 <= x && x <= 3 && -3 <= y && y <= 3;
}

// Components

function Row({ children, ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className="row" {...props}>
      {children}
    </div>
  );
}

function Donation({ token, tokenId, isOwner }: { token: IData | null; tokenId: string; isOwner: boolean }) {
  const { account, contract: PAB } = useWeb3();
  const [isLoading, setIsLoading] = useState(false);
  const [amountEth, setAmountEth] = useState("0");
  const [message, setMessage] = useState("");
  const filter = useMemo(() => ({ item: tokenId }), [tokenId]);
  const donations = useEvents("Donation", filter);

  async function handleDonation() {
    setIsLoading(true);
    try {
      await PAB.methods
        .donate(tokenId, ethToWei(amountEth), message, account)
        .send({ value: ethToWei(amountEth).toString() });
      setMessage("");
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  function handleMessageChange(message: string) {
    // Limit message length
    if (message.length <= 280) setMessage(message);
  }

  const isDisabled = isLoading || account === null || token === null;

  return (
    <div className="donation">
      <div className="log donation">
        {donations.map(({ event, date }) => {
          const { from, to, amount, message } = event.returnValues;
          return (
            <div key={event.id} className="log-item">
              <div className="content">
                <Icon>person</Icon>
                <Name>{from}</Name>
                <span className="arrow">→</span>
                {weiToEth(amount)} ETH
                <span className="arrow">→</span>
                <Name>{to}</Name>
                <a
                  className="open-etherscan"
                  href={`https://etherscan.io/nft/0x87124d405096581eb8774341a001ceffa06ea6d0/${event.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon>open_in_new</Icon>
                </a>
              </div>
              <div className="content">{message}</div>
              <div className="timestamp">{dateToString(date)}</div>
            </div>
          );
        })}
      </div>
      {!isOwner && (
        <>
          <div className="box">
            <input
              disabled={isDisabled}
              type="text"
              placeholder="Comment... (Max 280 characters)"
              value={message}
              onChange={(e) => handleMessageChange(e.target.value)}
            ></input>
          </div>
          <div className="box">
            <input
              type="number"
              value={amountEth}
              onChange={(e) => setAmountEth(e.target.value)}
              disabled={isDisabled}
            ></input>
            <div className="unit">ETH</div>
            <button disabled={isDisabled} onClick={handleDonation}>
              Donate
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function Logs({ tokenId }: { tokenId: string }) {
  const filter = useMemo(() => ({ item: tokenId }), [tokenId]);

  const logs = useEvents("Transaction", filter);

  return (
    <div className="log history">
      {logs.map(({ event, date }) => {
        const { from, to, price } = event.returnValues;
        return (
          <div key={event.id} className="log-item">
            <div className="content">
              <Name>{from}</Name>
              <span className="arrow">→</span>
              {weiToEth(price)} ETH
              <span className="arrow">→</span>
              <Name>{to}</Name>
              <Icon>open_in_new</Icon>
            </div>
            <div className="timestamp">{dateToString(date)}</div>
          </div>
        );
      })}
    </div>
  );
}

function Attr({ children }: { children: string }) {
  return <span className="key">{children}</span>;
}

function Value({ children }: { children: any }) {
  return <span className="value">{children}</span>;
}

function Split() {
  return <div className="split"></div>;
}

function TokenInfo({
  token,
  owner,
  id,
  x,
  y,
  setPriceEth,
  setIsEditorOpened,
  onUpdate,
}: {
  token: IData | null;
  owner: string;
  id: string;
  x: number;
  y: number;
  setData: Function;
  setPriceEth: Function;
  setIsEditorOpened: Function;
  onUpdate: () => void;
}) {
  const { account, contract: PAB, connect } = useWeb3();
  const [tab, setTab] = useState(0);
  const { admin } = useAdmin();

  const isOwner = account === owner;

  async function handleBuy() {
    if (!token) return;
    const { price } = token;

    // Send evt to GA
    ReactGA.event('begin_checkout', {
      value: weiToEth(BigInt(price)),
      currency: 'ETH',
      items: [{
        item_id: (x + ',' + y),
        currency: 'ETH',
        price: weiToEth(BigInt(price)),
      }]
    });

    // Log balance
    console.log("PRE", await PAB.methods.getBalance().call());
    const feeRate: bigint = await PAB.methods.getFeeRate().call();
    const requiredValue = (BigInt(price) * (BigInt(1000) + BigInt(feeRate))) / BigInt(1000);
    const buyRequest = PAB.methods.buy(x, y, account);
    await buyRequest.send({ gas: "1000000", value: requiredValue.toString() });
    console.log("POST", await PAB.methods.getBalance().call());
  }

  let button = <></>;

  if (account === null) {
    button = (
      <button className="edit-tile login-with-metamask" onClick={() => connect}>
        <img className="metamask-icon" src="/icons/metamask-fox.svg" alt="metamask-icon"></img>
        LOGIN WITH METAMASK
      </button>
    );
  }

  /* eslint-disable-next-line */
  const TOKEN_URL = location.host + `?x=${x}&y=${y}`;

  async function copy(text: string) {
    await navigator.clipboard.writeText(text);
    alert("Copied to clipboard");
  }

  return (
    <>
      {button}
      <button className="edit-tile" onClick={() => setIsEditorOpened(true)} disabled={account === null || !isOwner}>
        <Icon>brush</Icon>
        <span>DRAW ON THIS TILE</span>
      </button>
      <div className="title">
        <Icon>info</Icon>
        <span>Tile Information</span>
      </div>
      <Row>
        <Icon>push_pin</Icon>
        <Attr>Position</Attr>
        <Value>
          ({x}, {y})
        </Value>
      </Row>
      <Row>
        <Icon>link</Icon>
        <Attr>URL</Attr>
        <Value>
          {TOKEN_URL}
          <button className="copy" onClick={() => copy(TOKEN_URL)}>
            <Icon>copy_content</Icon>
          </button>
        </Value>
      </Row>
      <Row>
        <Icon>toll</Icon>
        <Attr>Token ID</Attr>
        {/* eslint-disable-next-line */}
        <Value>
          <Address>{id}</Address>
          <button
            className="copy"
            onClick={() =>
              window.open("https://etherscan.io/nft/0x87124d405096581eb8774341a001ceffa06ea6d0/" + id, "_blank")
            }
          >
            <Icon>open_in_new</Icon>
          </button>
        </Value>
      </Row>
      <Split />
      <div className="title">
        <Icon>edit</Icon>
        <span>Author</span>
      </div>
      <Row>
        <Icon>person</Icon>
        <Attr>Nickname</Attr>
        <Value>
          <Name>{owner}</Name>
        </Value>
      </Row>
      {owner !== admin && (
        <Row>
          <Icon>wallet</Icon>
          <Attr>Wallet Address</Attr>
          <Value>
            <Address>{owner}</Address>
            <button className="copy" onClick={() => window.open("https://etherscan.io/address/" + owner, "_blank")}>
              <Icon>open_in_new</Icon>
            </button>
          </Value>
        </Row>
      )}
      <Split />
      <div className="title">
        <img className="eth-icon" src="/icons/ethereum-brands-white.svg" alt="eth-icon"></img>
        <span>Price</span>
        <div className="price">
          <input
            type="number"
            value={token ? weiToEth(token.price) : 0}
            disabled={owner !== account || token === null}
            onChange={(e) => setPriceEth(e.target.value)}
          ></input>
          <div className="unit">ETH</div>
          {isOwner ? (
            <button onClick={onUpdate}>EDIT PRICE</button>
          ) : (
            <button onClick={handleBuy} disabled={account === null || token === null}>
              BUY THIS TILE
            </button>
          )}
        </div>
        <div className="price-description">* 2.5% of price will be added as a service fee.</div>
      </div>
      <Split />
      <div className="history-box">
        <div className="tabs">
          <button className={tab === 0 ? "selected" : ""} onClick={() => setTab(0)}>
            Donation &amp; Comments
          </button>
          <button className={tab === 1 ? "selected" : ""} onClick={() => setTab(1)}>
            Trading History
          </button>
        </div>
        <div className="content">
          {tab === 0 ? <Donation tokenId={id} token={token} isOwner={isOwner}></Donation> : <Logs tokenId={id} />}
        </div>
      </div>
      <div className="footer">
        <p>
          © 2022 PABNFT |{" "}
          <a href="/legal-disclaimer.html" target="_blank" rel="noreferrer">
            Legal Disclaimer
          </a>
        </p>
      </div>
    </>
  );
}

function Admin({ selected }: { selected: number[] }) {
  const { account, contract: PAB } = useWeb3();
  const [x, y] = selected;
  const [priceEth, setPriceEth] = useState("1");
  const [feeRate, setFeeRate] = useState("0");

  async function handleMint() {
    await PAB.methods.mint(account, x, y, "", 32, ethToWei(priceEth).toString()).send();
  }

  async function handleFeeRateUpdate() {
    await PAB.methods.setFeeRate(feeRate).send();
  }

  useEffect(() => {
    async function loadFeeRate() {
      const feeRate = await PAB.methods.getFeeRate().call();
      setFeeRate(feeRate);
    }

    loadFeeRate();
  }, [PAB.methods]);

  return (
    <div className="side-bar">
      <div>
        <strong>Admin Page</strong>
        <div>
          Token position = ({x},{y})
        </div>
        <div>
          <input value={priceEth} onChange={(e) => setPriceEth(e.target.value)} type="number"></input>
        </div>
        <div>
          <button onClick={handleMint}>Mint this token</button>
        </div>
        <hr></hr>
        <div>
          <input type="number" value={feeRate} onChange={(e) => setFeeRate(e.target.value)} />
        </div>
        <div>
          <button onClick={handleFeeRateUpdate}>Update fee rate</button>
        </div>
      </div>
    </div>
  );
}

function SideBar({
  selected,
  showGrid,
  showSidebar,
  onClose,
  setShowGrid,
}: {
  showGrid: boolean;
  setShowGrid: (showGrid: boolean) => any;
  showSidebar: boolean;
  selected: number[];
  onClose: () => any;
}) {
  const { contract: PAB } = useWeb3();
  const [x, y] = selected;
  const { owner, id, token, setToken } = useToken(x, y);
  const [isEditorOpened, setIsEditorOpened] = useState(false);
  const { isAdmin } = useAdmin();

  if (token === null) {
    if (isAdmin) return <Admin selected={selected} />;
  }

  function setSize(newSize: number) {
    if (token === null) return;
    const { size } = token;
    let limitedSize = newSize;
    limitedSize = Math.floor(newSize);
    if (limitedSize > 128) limitedSize = 128;
    if (size === newSize) return;
    setToken({ ...token, size: limitedSize });
  }

  function setData(newData: string) {
    if (token === null) return;
    setToken({ ...token, data: newData });
  }

  function setPriceEth(newPrice: number) {
    if (token === null) return;
    setToken({ ...token, price: ethToWei(newPrice) });
  }

  async function handleUpdate() {
    if (!token) return;
    const { data, size, price } = token;
    const newDataLength = size * size * 3;
    const limitedData = Buffer.from(data, "base64").subarray(0, newDataLength).toString("base64");
    await PAB.methods.setTokenData(x, y, limitedData, size, price.toString()).send();
  }

  function handleEditorClose(shouldSave: boolean) {
    setIsEditorOpened(false);
    if (shouldSave) handleUpdate();
  }

  return (
    <>
      <div className={"side-bar " + (showSidebar ? "" : "hidden")}>
        <div className="header">
          <span>PAB(Pixel Art Board) NFT</span>
          <button className="close-sidebar" onClick={onClose}>
            <Icon>close</Icon>
          </button>
        </div>
        <TokenInfo
          token={token}
          setData={setData}
          setPriceEth={setPriceEth}
          onUpdate={handleUpdate}
          owner={owner}
          id={id}
          x={x}
          y={y}
          setIsEditorOpened={setIsEditorOpened}
        ></TokenInfo>
      </div>
      {token !== null && (
        <div hidden={!isEditorOpened}>
          <Editor
            value={token.data}
            onChange={setData}
            onClose={handleEditorClose}
            size={token.size}
            setSize={setSize}
            x={x}
            y={y}
          />
        </div>
      )}
      <div className={"checkboxes " + (showSidebar ? "" : "hidden")}>
        <Checkbox isSelected={showGrid} onChange={setShowGrid}>
          {[
            <>
              <Icon>visibility</Icon>Grid
            </>,
            <>
              <Icon>visibility_off</Icon>Grid
            </>,
          ]}
        </Checkbox>
      </div>
    </>
  );
}

// Root component
function App() {
  // Query parameters
  const [params, setParams] = useSearchParams();
  const px = +(params.get("x") || 0);
  const py = +(params.get("y") || 0);

  // States
  const [center, setCenter] = useState([px, py]);
  const [showGrid, setShowGrid] = useState(false);
  const [isHolding, setIsHolding] = useState(false);
  const [isTouchMoved, setIsTouchMoved] = useState(false);
  const [touchStartPosX, setTouchStartPosX] = useState(0);
  const [touchStartPosY, setTouchStartPosY] = useState(0);
  const [grabPoint, setGrabPoint] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTile, _setSelectedTile] = useState<[number, number] | null>(null);

  // References
  const unitRef = useRef(128); // Size of tile in actual pixel
  const tilesRef = useRef<{ [Key: string]: IBlock }>({});
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const centerRef = useRef([px, py]);
  const _selectedRef = useRef(selectedTile);
  const pinchDistRef = useRef(-1);
  const canvasSizeRef = useRef([0, 0]);
  const mouseCurrentPosRef = useRef([0, 0]);

  // Custom hooks
  const { contract: PAB } = useWeb3();
  const { isAdmin } = useAdmin();

  /**
   * Main canvas render function.
   * This function must be called whenever canvas should be updated.
   * (e.g. Dragging)
   */
  const render = useCallback(() => {
    const cnv = canvasRef.current;
    if (cnv === null) return;
    const ctx = cnv.getContext("2d");
    if (ctx === null) return;

    const [w, h] = canvasSizeRef.current;
    const [cx, cy] = centerRef.current;
    const selection = _selectedRef.current;
    const [mx, my] = mouseCurrentPosRef.current;
    const unit = unitRef.current;

    // Assume that pivot of a PAV is center of square.
    ctx.fillStyle = "#e0e0e0";
    ctx.fillRect(0, 0, w, h);
    ctx.beginPath();
    Object.values(tilesRef.current).forEach(({ x, y, data, size }) => {
      const pixelSize = unit / size;

      // Center of given tile
      const centerX = w / 2 + x * unit - cx * unit;
      const centerY = h / 2 + y * unit - cy * unit;
      const startX = centerX - unit / 2;
      const startY = centerY - unit / 2;
      const endX = startX + unit;
      const endY = startY + unit;
      if (startX > w || startY > h || endX < 0 || endY < 0) return;

      // Block has content
      const decoded = decode(data);

      // Draw background
      if (size > 0) {
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(startX, startY, unit + 1, unit + 1);
      }

      if (decoded.length > 0) {
        for (let i = 0; i < size; i++) {
          for (let j = 0; j < size; j++) {
            const index = (i * size + j) * 3;
            if (index + 2 >= decoded.length) continue;
            ctx.fillStyle = rgbSubArr2str(decoded, index);
            ctx.fillRect(startX + j * pixelSize, startY + i * pixelSize, pixelSize, pixelSize);
          }
        }
      }

      // Draw outline when grid option is on
      if (showGrid) {
        ctx.rect(startX, startY, unit, unit);
      }

      // If current box is selected and minted, draw outline.
      // Admin can click unminted tiles.
      if (selection && x === selection[0] && y === selection[1] && (size > 0 || isAdmin)) {
        const THICK = 5;
        ctx.fillStyle = "#FFC600";
        ctx.fillRect(startX, startY, unit, THICK);
        ctx.fillRect(startX, startY, THICK, unit);
        ctx.fillRect(startX, startY + unit - THICK, unit, THICK);
        ctx.fillRect(startX + unit - THICK, startY, THICK, unit);
      }

      // Draw border for hovering tile
      if (x === mx && y === my && (size > 0 || isAdmin)) {
        const THICK = 2;
        ctx.fillStyle = "#00FF75";
        ctx.fillRect(startX, startY, unit, THICK);
        ctx.fillRect(startX, startY, THICK, unit);
        ctx.fillRect(startX, startY + unit - THICK, unit, THICK);
        ctx.fillRect(startX + unit - THICK, startY, THICK, unit);
      }
    });
    ctx.stroke();

    ctx.beginPath();
    const thick = 16;
    const fontSize = 14;

    // Horizontal scale
    {
      const visibleBlocks = w / unit / 2;
      const start = Math.floor(cx - visibleBlocks);
      const end = Math.ceil(cx + visibleBlocks);
      ctx.font = `${fontSize}px`;
      for (let i = start; i <= end; i++) {
        const x = (i - cx - 0.5) * unit + w / 2;
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(x, 0, unit, thick);
        ctx.rect(x, 0, unit, thick);
        ctx.fillStyle = "#000000";
        ctx.fillText(`${i}`, x + unit / 2, thick - 4);
      }
    }

    // Vertical scale
    {
      const visibleBlocks = h / unit / 2;
      const start = Math.floor(cy - visibleBlocks);
      const end = Math.ceil(cy + visibleBlocks);
      ctx.font = `${fontSize}px`;
      for (let i = start; i <= end; i++) {
        const y = (i - cy - 0.5) * unit + h / 2;
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, y, thick, unit);
        ctx.rect(0, y, thick, unit);
        ctx.fillStyle = "#000000";
        ctx.fillText(`${i}`, 4, y + unit / 2);
      }
    }
    ctx.stroke();

    // Corner
    ctx.beginPath();
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, thick, thick);
    ctx.rect(0, 0, thick, thick);
    ctx.stroke();
  }, [isAdmin, showGrid]);

  function setSelectedTile(selection: [number, number] | null) {
    _setSelectedTile(selection);
    _selectedRef.current = selection;
    render();
  }

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      var dpr = window.devicePixelRatio || 1;
      const cnv = canvasRef.current;
      if (cnv === null) return;
      const ctx = cnv.getContext("2d");
      if (ctx === null) return;
      cnv.width = cnv.clientWidth * dpr;
      cnv.height = cnv.clientHeight * dpr;
      canvasSizeRef.current = [cnv.clientWidth, cnv.clientHeight];
      ctx.scale(dpr, dpr);
      render();
    };
    window.onresize = handleResize;
    handleResize();
  }, [render]);

  // The main token loading function.
  const updateBlock = useCallback(
    async (x: number, y: number) => {
      // Check if given token is minted
      if (!isPositionMinted(x, y)) return;

      const hash = getBlockHash(x, y);
      if (hash in tilesRef.current) return;
      tilesRef.current[hash] = { x, y, size: 0, data: "" };  // Remove here if you don't want to use cache
      try {
        const token = await PAB.methods.getTokenData(x, y).call();
        tilesRef.current[hash] = { x, y, ...token };
        render();
      } catch (e) {
        tilesRef.current[hash] = { x, y, size: 0, data: "" };
      }
    },
    [PAB.methods, render]
  );

  // Load tiles visible in screen
  const loadTiles = useCallback(async () => {
    if (isLoading) return;
    // Get range
    const [cx, cy] = centerRef.current;

    const [w, h] = canvasSizeRef.current;
    const unit = unitRef.current;
    const sx = Math.floor(cx - w / 2 / unit);
    const sy = Math.floor(cy - h / 2 / unit);
    const ex = Math.ceil(cx + w / 2 / unit);
    const ey = Math.ceil(cy + h / 2 / unit);

    for (let y = sy; y <= ey; y++) {
      for (let x = sx; x <= ex; x++) {
        await updateBlock(x, y);
      }
    }
    setIsLoading(false);
  }, [isLoading, updateBlock]);

  /**
   * Periodically load tiles.
   * This is not expensive because all loaded tiles are cached.
   */
  useEffect(() => {
    const id = setInterval(() => {
      setIsLoading(true);
      loadTiles();
    }, 1000);
    return () => clearInterval(id);
  }, [loadTiles]);

  interface IPosition {
    clientX: number;
    clientY: number;
  }

  interface IZoom {
    deltaY: number;
  }

  function handleGrab({ clientX, clientY }: IPosition) {
    setIsHolding(true);
    setGrabPoint([clientX, clientY]);
  }

  function handleMove({ clientX, clientY }: IPosition) {
    const [w, h] = canvasSizeRef.current;
    const [cx, cy] = centerRef.current;
    const unit = unitRef.current;
    mouseCurrentPosRef.current = [
      Math.floor((clientX - w / 2) / unit + cx + 0.5),
      Math.floor((clientY - h / 2) / unit + cy + 0.5),
    ];
    if (isHolding) {
      const [x0, y0] = grabPoint;
      const [cx, cy] = center;
      const unit = unitRef.current;
      const dx = (clientX - x0) / unit;
      const dy = (clientY - y0) / unit;
      centerRef.current = [cx - dx, cy - dy];
    }
    render();
  }

  function handleRelease() {
    setCenter(centerRef.current);
    setIsHolding(false);
    const [cx, cy] = centerRef.current;
    const param = new URLSearchParams();
    param.set("x", `${cx}`);
    param.set("y", `${cy}`);
    setParams(param);
    pinchDistRef.current = -1;
  }

  function handleClick({ clientX, clientY }: IPosition) {
    const [w, h] = canvasSizeRef.current;
    const [cx, cy] = centerRef.current;
    const unit = unitRef.current;
    const x = Math.floor((clientX - w / 2) / unit + cx + 0.5);
    const y = Math.floor((clientY - h / 2) / unit + cy + 0.5);
    const hash = getBlockHash(x, y);
    if (!(hash in tilesRef.current)) {
      setSelectedTile(null);
    } else {
      setSelectedTile([x, y]);
    }
  }

  function handleScroll(e: IZoom) {
    unitRef.current -= e.deltaY;
    if (unitRef.current < MIN_BLOCK_SIZE) unitRef.current = MIN_BLOCK_SIZE;
    if (unitRef.current > MAX_BLOCK_SIZE) unitRef.current = MAX_BLOCK_SIZE;
    render();
  }

  function handlePinch(e: React.TouchEvent<HTMLCanvasElement>) {
    e.preventDefault();

    const touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    const touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY };

    // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
    const currentDistance = (touch1.x - touch2.x) ** 2 + (touch1.y - touch2.y) ** 2;

    if (pinchDistRef.current < 0) {
      pinchDistRef.current = currentDistance;
    } else {
      const zoom = { deltaY: currentDistance / pinchDistRef.current };
      handleScroll(zoom);
    }
  }

  function WrapTouch(e: any) {

    e.stopPropagation();
    e.preventDefault();  // please consider Canvas DOM event handler is passive or not
  
    if (e.touches.length === 0 || e.touches.length === 1) {
      if (e.type === "touchstart") {
        handleGrab(e.touches[0]);
        setTouchStartPosX(e.touches[0]['clientX']);
        setTouchStartPosY(e.touches[0]['clientY']);
      } else if (e.type === "touchmove") {
        setIsTouchMoved(true);
        handleMove(e.touches[0]);
      } else if (e.type === "touchend") {
        if (isTouchMoved === false) {
          handleMove({ 'clientX': touchStartPosX, 'clientY': touchStartPosY });
          handleClick({ 'clientX': touchStartPosX, 'clientY': touchStartPosY });
        }
        setIsTouchMoved(false);  // Init.
        handleRelease();
      }
    } else if (e.type === "touchmove" && e.touches.length === 2) {
      handlePinch(e);
    }

    return false;  // for prevent scroll event
  }

  useEffect(() => {

    const cnv = canvasRef.current;
    if (cnv === null) return;
    cnv.addEventListener('touchstart', WrapTouch, {passive: false});
    cnv.addEventListener('touchmove', WrapTouch, {passive: false});
    cnv.addEventListener('touchend', WrapTouch, {passive: false});
    return () => {
      cnv.removeEventListener('touchstart', WrapTouch);
      cnv.removeEventListener('touchmove', WrapTouch);
      cnv.removeEventListener('touchend', WrapTouch);
    }
  });

  return (
    <div className="app">
      <canvas
        id="main-canvas"
        ref={canvasRef}
        onClick={handleClick}
        onMouseDown={handleGrab}
        onMouseLeave={handleRelease}
        onMouseUp={handleRelease}
        onMouseMove={handleMove}
        onWheel={handleScroll}
      ></canvas>
      <SideBar
        showSidebar={!!selectedTile}
        onClose={() => setSelectedTile(null)}
        selected={selectedTile || [0, 0]}
        showGrid={showGrid}
        setShowGrid={setShowGrid}
      ></SideBar>
      {isLoading && (
        <div className="dim-screen">
          <span className="spinner">
            <div className="cssload-thecube">
              <div className="cssload-cube cssload-c1"></div>
              <div className="cssload-cube cssload-c2"></div>
              <div className="cssload-cube cssload-c4"></div>
              <div className="cssload-cube cssload-c3"></div>
            </div>
          </span>
        </div>
      )}
    </div>
  );
}

export default withAccount(App);
