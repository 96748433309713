import { Config, adjectives, animals, colors, uniqueNamesGenerator } from "unique-names-generator";

import { useAdmin } from "hooks/useAdmin";

export function Name({ children }: { children: string | null }) {
  const { admin } = useAdmin();

  if (!children || children.length === 0) {
    return (
      <abbr about="null" title="null">
        NULL
      </abbr>
    );
  }

  if (children === admin) {
    return <span>PABNFT.com</span>;
  }

  const config: Config = {
    dictionaries: [adjectives, colors, animals],
    separator: "-",
    seed: children,
  };

  const nameFromSeed: string = uniqueNamesGenerator(config);

  return (
    <abbr about={"Auto generated by Wallet Addr: " + children} title={"Auto generated by Wallet Addr: " + children}>
      {nameFromSeed}
    </abbr>
  );
}
