import { useEffect, useState } from "react";

import { useWeb3 } from "utils/web3";

export function useAdmin() {
  const { account, contract: PAB } = useWeb3();
  const [admin, setAdmin] = useState("");
  const isAdmin = admin === account;

  useEffect(() => {
    async function loadAdmin() {
      try {
        const admin = (await PAB.methods.getAdmin().call()).toLowerCase();
        setAdmin(admin);
      } catch (e) {
        console.error(e);
        console.log("Above error occurred while getting admin info.");
      }
    }
    loadAdmin();
  }, [PAB.methods]);

  return { admin, isAdmin };
}
