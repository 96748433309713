import { useEffect, useState } from "react";

import { useWeb3 } from "utils/web3";

export interface IData {
  data: string;
  size: number;
  price: bigint;
}

export function useToken(x: number, y: number) {
  const [owner, setOwner] = useState("");
  const [id, setId] = useState("");
  const [token, setToken] = useState<IData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { contract: PAB } = useWeb3();

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      try {
        const [id, owner, token] = await Promise.all([
          PAB.methods.hash(x, y).call(),
          PAB.methods.ownerOf(x, y).call(),
          PAB.methods.getTokenData(x, y).call(),
        ]);
        setId(id);
        setOwner(owner.toLowerCase());
        setToken(token);
      } catch {
        setOwner("");
        setToken(null);
      }
      setIsLoading(false);
    }
    load();
  }, [x, y, PAB.methods]);

  return { owner, id, token, setToken, isLoading };
}
