import { useEffect, useState } from "react";

import { useWeb3 } from "utils/web3";

interface IEvent {
  address: string;
  blockHash: string;
  blockNumber: number;
  event: string;
  id: string;
  logIndex: number;
  raw: { data: string };
  removed: boolean;
  returnValues: any;
  signature: string;
  transactionHash: string;
  transactionIndex: number;
  type: string;
}

export interface ILog {
  event: IEvent;
  block: any;
  date: Date;
}

// Custom hooks
export function useEvents(eventName: string, filter: any) {
  const [events, setEvents] = useState<ILog[]>([]);
  const { web3, contract: PAB } = useWeb3();

  useEffect(() => {
    async function loadEvents() {
      const transactions = await PAB.getPastEvents(eventName, {
        filter,
        fromBlock: 0,
        toBlock: "latest",
      });

      const totalEvents = [...transactions]
        .sort((a, b) => {
          return a.blockNumber - b.blockNumber;
        })
        .map(async (event) => {
          const block = await web3.eth.getBlock(event.blockNumber);
          const timestamp: number = +block.timestamp;
          const date = new Date(timestamp * 1000);
          return { event, block, date };
        });

      setEvents(await Promise.all(totalEvents));
    }

    loadEvents();
  }, [filter, PAB, eventName, web3.eth]);

  return events;
}
