export function readImageFile(file: File, unit = 16) {
  return new Promise<Buffer>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx === null) return;
    const image = new Image();
    image.onload = function () {
      canvas.width = unit;
      canvas.height = unit;
      ctx.drawImage(image, 0, 0);
      const { data } = ctx.getImageData(0, 0, unit, unit);
      const rgbArray = Buffer.alloc(unit * unit * 3, 255);
      let i = 0;
      let j = 0;
      while (i < data.length && j < rgbArray.length) {
        rgbArray[j++] = data[i++];
        rgbArray[j++] = data[i++];
        rgbArray[j++] = data[i++];
        i++;
      }
      resolve(rgbArray);
    };
    image.src = URL.createObjectURL(file);
  });
}
