export interface ICheckboxProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, "onChange"> {
  isSelected: boolean;
  onChange: (value: boolean) => any;
  children: [React.ReactNode, React.ReactNode];
}

export default function Checkbox({ isSelected, onChange, children, ...others }: ICheckboxProps) {
  let content;

  if (isSelected) content = children[0];
  else content = children[1];

  return (
    <button
      style={{
        backgroundColor: "#252525",
        border: "solid 1px #00FF75",
        padding: "6px 15px",
        color: "#ffffff",
        fontSize: "14px",
      }}
      onClick={() => onChange(!isSelected)}
      {...others}
    >
      {content}
    </button>
  );
}
