export function rgb2str(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function rgbArr2str(arr: [number, number, number]) {
  return rgb2str(...arr);
}

export function rgbSubArr2str(arr: number[] | Buffer, i: number) {
  return rgb2str(arr[i + 0], arr[i + 1], arr[i + 2]);
}
