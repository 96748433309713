import { AbiItem } from "web3-utils";
import config from "./config.json";

interface INetworkSetting {
  [Key: string]: { address: string };
}

const networks: INetworkSetting = config.networks;
const networkId = Object.keys(networks)[0];
export const PAB_ADDRESS: string = networks[networkId].address;
export const PAB_ABI = config.abi as AbiItem[];
