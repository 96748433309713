import { compressToBase64, decompressFromBase64 } from "lz-string";

export function encode(bytes: Buffer) {
  const original = bytes.toString("base64");
  const encoded = compressToBase64(original);
  return encoded;
}

export function decode(string: string) {
  const decompressed = decompressFromBase64(string);
  if (decompressed === null) return Buffer.alloc(0);
  return Buffer.from(decompressed, "base64");
}
